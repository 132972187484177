<template>
	<div class="edit_info">
		<!-- tab -->
		<div class="tab">
			<div class="tab_l">
				<div v-for="(item,index) in tabList" :key="index"
					:class="['tab_item',{'active_item': currentTabIndex === index}]" @click="handleTabChange(index)">
					{{item.name}}
				</div>
			</div>
		</div>
		<!-- 列表 -->
		<div class="content">
			<div class="list" v-if="currentTabData.length > 0">
				<div v-for="(item,index) in currentTabData" :key="index" class="item">
					<div class="item_l">
						<img class="live_cover" :src="item.surfacePlot" alt="">
						<div class="icon">
							<img src="../../assets/live/liveNow.png" alt="" srcset="" v-if="currentTabIndex === 0">
							<img src="../../assets/live/icon_see.png" alt="" srcset="" v-if="currentTabIndex === 1">
							<img src="../../assets/live/icon_time.png" alt="" srcset="" v-if="currentTabIndex === 2">
						</div>
					</div>
					<div class="item_r">
						<div class="item_r_l">
							<div class="live_name">{{item.name}}</div>
							<div class="live_introduce">
								<div class="introduce">简介：{{item.introduce}}</div>
								<div class="price"><span style="font-size:12px;">￥</span>{{item.cost}}</div>
							</div>
							<div class="live_time">直播时间：{{item.beginTime}}</div>
						</div>
						<div class="item_r_r">
							<div class="btn operate_btn" v-if="currentTabIndex === 0" @click="handleWatchLive(item.id)">
								观看直播</div>
							<div class="btn operate_btn" v-if="currentTabIndex === 1" @click="handleLiveReplay(item)">
								回看直播</div>
							<div class="btn" @click="handleDeitails(item.id)">查看详情</div>
						</div>
					</div>
				</div>
			</div>
			<div class="no_data" v-else>
				<div class="no_data_img">
					<img src="../../assets/imgs/4.png" alt="">
					<div v-if="currentTabIndex === 0">您还没有正在直播的数据哦~~</div>
					<div v-if="currentTabIndex === 1">您还没有已结束的直播哦~~</div>
					<div v-if="currentTabIndex === 2">您还没有预约的直播哦~~</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				currentTabIndex: 0, //当前tab索引
				tabList: [{
					type: 1,
					name: '正在直播'
				}, {
					type: 0,
					name: '我预约的'
				}], //tab数据
				currentTabData: [], //当前页面数据
			}
		},
		created() {
			this.getLiveList()
		},
		methods: {
			// tab切换
			handleTabChange(i) {
				this.currentTabIndex = i;
				this.currentTabData = [];
				this.getLiveList()
			},
			// 查看详情
			handleDeitails(id) {
				this.$router.push({
					path: '/livedetails',
					query: {
						id
					}
				})
			},
			// 观看直播
			handleWatchLive(id) {
				this.$router.push({
					path: '/liveInfo',
					query: {
						id
					}
				})
			},
			// 直播回看
			handleLiveReplay(item) {
				if (item.list && item.list.length > 0) {
					localStorage.setItem('liveReplay', JSON.stringify(item.list))
					this.$router.push({
						path: '/liveViews',
						query: {
							index: 0
						}
					})
				} else {
					this.$message.warning('该直播暂无回看视频！')
				}

			},
			// 获取我的直播列表
			getLiveList() {
				let type = this.tabList[this.currentTabIndex].type;
				this.$http.post('myLivesApi', {
					type
				}).then(res => {
					if (res.code == 200) {
						this.currentTabData = res.data
					} else {
						this.currentTabData = [];
						this.$message.error(res.message)
					}
				})
			}
		}
	}
</script>
<style lang="less" scoped>
	.edit_info {
		width: 974px;
		height: 800px;
		overflow: auto;
		background-color: #fff;
	}

	.tab {
		border-bottom: 1px solid #EEEEEE;
		height: 64px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.tab_l {
			display: flex;
			align-items: center;

			.tab_item {
				font-size: 24px;
				color: #333333;
				width: 142px;
				text-align: center;
				height: 64px;
				line-height: 64px;
				cursor: pointer;
			}

			.active_item {
				font-weight: bold;
				color: #39AEFF;
				border-bottom: 2px solid #39AEFF;
				box-shadow: 0px 0px 13px 0px rgba(53, 53, 53, 0.13);
			}
		}
	}

	.list {
		padding: 0px 8px;

		.item {
			display: flex;
			align-items: center;
			border-bottom: 1px solid #EEEEEE;
			padding: 25px 35px;

			&:last-child {
				border-bottom: none;
			}

			.item_l {
				width: 124px;
				height: 114px;
				// background: #000000;
				// opacity: 0.48;
				position: relative;

				.live_cover {
					width: 100%;
					height: 100%;
				}

				.icon {
					position: absolute;
					left: 0;
					top: 0;
					right: 0;
					bottom: 0;
					background: rgba(0, 0, 0, .48);

					img {
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
					}
				}
			}

			.item_r {
				padding: 8px 0;
				flex: 2;
				margin-left: 24px;
				display: flex;
				align-items: center;

				.item_r_l {
					flex: 2;

					.live_name {
						font-size: 20px;
						font-weight: 500;
						color: #333333;
						margin-bottom: 22px;
					}

					.live_introduce {
						display: flex;
						margin-bottom: 22px;

						.introduce {
							flex: 2;
							width: 200px;
							font-size: 16px;
							color: #333333;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}

						.price {
							font-size: 16px;
							font-weight: 500;
							color: #39AEFF;
						}
					}

					.live_time {
						font-size: 16px;
						font-weight: 500;
						color: #333333;
					}
				}

				.item_r_r {
					margin-left: 24px;

					.btn {
						width: 128px;
						height: 37px;
						line-height: 37px;
						border: 1px solid #39AEFF;
						font-size: 14px;
						font-weight: 500;
						color: #39AEFF;
						text-align: center;
						cursor: pointer;
					}

					.operate_btn {
						background: linear-gradient(163deg, #39AEFF 0%, #FF6759 100%);
						color: #FFFFFF;
						margin-bottom: 4px;
					}
				}
			}
		}
	}

	.no_data {
		color: #666;
		height: 60vh;
		text-align: center;
		margin-top: 200px;
		font-size: 14px;
		color: #ccc;

		.no_data_img {
			img {
				width: 188px;
				height: 141px;
				margin-bottom: 10px;
			}
		}
	}
</style>
