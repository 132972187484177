<template>
	<div class="editInfoContent">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="错题库" name="first">
				<div v-if="list.length > 0" class="content">
					<el-collapse v-model="activeNames" @change="handleChange">
						<el-collapse-item v-for='(item,idx) in list' :key='idx' :title="item.name">
							<div v-for="(it,index) in item.errorList" :key="index" class="question">
								<div class="it_content"><span>{{index+1}}</span>.<span>{{it.name}}</span></div>
								<div>
									<el-button class="bnt1" @click="showEvt(it,index+1)">查看</el-button>
									<el-button @click="delAnswer(it)" class="bnt2">移除</el-button>
								</div>
							</div>
						</el-collapse-item>
					</el-collapse>
				</div>
				<div class="noTeacter" v-else-if="list.length === 0">
					<div class="noTeacter_img">
						<img src="../../assets/imgs/4.png" alt="">
						<div>抱歉，您还没有错题库</div>
					</div>
				</div>
			</el-tab-pane>

		</el-tabs>
		<el-dialog :visible.sync="dialogVisible"  append-to-body width="30%"
			:before-close="handleClose">
			<p>{{index}}. {{item.name}}</p>
			<div style="margin-top:25px;">
				<ul style="margin-top:12px">
					<li v-for="(it,idx) in item.options" :key="idx" style="margin-top:14px">

						<span style="display: inline-block;margin-left:10px; ">{{it.idStr}}. {{it.item}}</span>
					</li>
				</ul>

			</div>
			<div style="margin-top:40px;display: flex;justify-content: center;">
				<div class="bntErr">回答错误</div>
			</div>
			<div style="margin-top:40px;">
				<ul class="flex">
					<li style="margin-right:30px">
						<i class="el-icon-success" style="color:#08A500;font-size:15px"></i>
						<span style="display: inline-block;margin-left:10px; margin-right:20px">正确答案:</span>
						<span style="color:#08A500;">{{item.answer}}</span>
					</li>
					<li>
						<i class="el-icon-error" style="color:#39AEFF;font-size:15px"></i>
						<span style="display: inline-block;margin-left:10px;margin-right:20px">我的答案:</span>
						<span style="color:#39AEFF;">{{item.stuAnswer}}</span>
					</li>
				</ul>
			</div>
		</el-dialog>
	</div>

</template>

<script>
	export default {
		name: 'WrongQuestion',
		data() {
			return {
				activeName: 'first',
				activeNames: ['1'],
				list: [

				],
				dialogVisible: false,
				item: {},
				index: 0
			};
		},
		created() {
			this.$http.post('errorMineApi').then(res => {
				if (res.code === 200) {
					this.list = res.data.courseInfoList
					this.list.map(it => {
						it.errorList.map(it => {
							if (it.examType === 3) {

								it.options.push({
									item: '对',
									idStr: '1'
								})
								it.options.push({
									item: '错',
									idStr: '2'
								})
								return it
							}
						})
					})
				}
			})
		},
		methods: {
			//打开错题
			showEvt(it, index) {
				this.dialogVisible = true
				it.options.sort(function(a, b) {
					return a.idStr.localeCompare(b.idStr)

				})
				this.item = it
				this.index = index
			},
			//删除错题
			delAnswer(it) {
				this.$http.post('delErrorApi', {
					examId: it.id
				}).then(res => {
					if (res.code === 200) {
						this.$message.success('删除成功')
						this.$http.post('errorMineApi').then(res => {
							if (res.code === 200) {
								this.list = res.data.courseInfoList
								this.list.map(it => {
									it.errorList.map(it => {
										if (it.examType === 3) {
											it.options.push({
												item: '对',
												idStr: '1'
											})
											it.options.push({
												item: '错',
												idStr: '2'
											})
											return it
										}
									})
								})
							}
						})
					} else {
						this.$message.error(res.message)
					}
				})
			},
			handleClick() {

			},
			handleChange(val) {
				console.log(val);
			},
			handleClose() {
				this.dialogVisible = false
			}
		}

	}
</script>

<style lang="less" scoped>
	.editInfoContent {
		width: 974px;
		height: 800px;
		overflow: auto;
		background-color: #fff;


		/deep/ .el-tabs__item {
			font-size: 24px;
			padding-bottom: 19px;
			padding-left: 21px !important;
			line-height: 63px;
			height: 63px;
		}

		/deep/ .el-tabs__nav-scroll {
			height: 63px;
		}

		/deep/ .el-tabs__active-bar {
			// left: 24px;
			background-color: #39AEFF;
		}

		/deep/ .el-tabs__item:hover {
			color: #39AEFF;
		}

		/deep/.el-tabs__item.is-active {
			color: #39AEFF;
		}

	}

	/deep/ .el-dialog__header {
		padding: 0;
	}

	.round {
		display: inline-block;
		width: 13px;
		height: 13px;
		border: 1px solid;
		border-radius: 50%;
	}

	.bntErr {
		width: 94px;
		height: 34px;
		background: linear-gradient(163deg, #39AEFF 0%, #FF6759 100%);
		font-weight: bold;
		color: #FFFFFF;
		font-size: 14px;
		line-height: 34px;
		text-align: center;
	}

	.flex {
		display: flex;
	}

	.content {
		padding: 25px;

		/deep/ .el-collapse-item__header {
			color: #666666;
			background-color: #eee;
			font-size: 16px;
			padding: 17px 50px;
		}

		/deep/.el-collapse-item {
			margin-bottom: 20px;
		}

		.question {
			display: flex;
			justify-content: space-between;
			padding: 20px 0 20px 50px;
			font-size: 18px;

			.it_content {
				width: 700px;
			}

			.bnt1 {
				font-weight: bold;
				color: #39AEFF;
				border: 1px solid #39AEFF;
			}

			.bnt2 {
				font-weight: bold;
				color: #fff;
				background: linear-gradient(163deg, #39AEFF 0%, #FF6759 100%);
			}
		}

	}

	.noTeacter {
		// margin-left: 45%;
		color: #666;
		height: 60vh;
		text-align: center;
		margin-top: 200px;
		font-size: 14px;
		color: #ccc;

		.noTeacter_img {
			img {
				width: 188px;
				height: 141px;
				margin-bottom: 10px;
			}
		}
	}

	/deep/.el-dialog__headerbtn {
		top: 5px;
	}

	.el-dialog__body{
		max-height: 60vh;
	}
</style>
