<template>
	<div class="editInfoContent">
		<el-tabs v-model="activeName">
			<el-tab-pane label="学习档案" name="first">
				<div v-if="list.length > 0" class="info">
					<!-- <div class="notice">
						根据成都市人力资源和社会保障局相关规定，所有学员学时证明由成都市专业技术人员机构教育网统一发放并提供查询。
					</div> -->
					<div class="tab_r">
						<el-select v-model="yearsValue" placeholder="请选择年份" clearable @change="getArchive">
							<el-option v-for="(item,index) in yearsOptions" :key="index" :label="item" :value="item" />
						</el-select>
					</div>
					<div class="contentBox">
						<div v-for="it in list" :key="it.id" style="border-bottom:1px solid #eee">
							<div style="margin-top:10px;font-size:16px">
								<table width="100%" border="0" style="text-align: center;">
									<thead style="background: #EEEEEE;">
										<tr style="height:50px">
											<td style="width:170px">培训项目名称</td>
											<td style="width:390px">证书有效时间</td>
											<td style="width:100px" v-if="it.typeId == 1">获得学时</td>
											<td style="width:100px" v-else>获得学分</td>
											<td style="width:120px">考核状态</td>
											<td style="width:140px">操作</td>
										</tr>
									</thead>
									<tbody>
										<tr style="height:50px">
											<td>{{it.courseName}}</td>
											<td>{{it.validStartTIme}}至{{it.validEndTime}}</td>
											<td>{{it.studyTime}}</td>
											<td style="color:red">已通过</td>
											<td>
												<!-- <el-button class="bnt" @click="handleDownload(it)">下载证书</el-button> -->
												<a class="btn" :href="it.url" :download="it.name"
													target="_blank">下载证书</a>
											</td>
										</tr>


									</tbody>
								</table>
							</div>



						</div>
						<!-- 分页器-->
						<div
							style="position: absolute;bottom: 28px;left: 50%;transform: translateX(-50%);">
							<el-pagination background @current-change="handleCurrentChange" :current-page="page"
								:page-size="pageSize" layout="prev, pager, next" :total="total">
							</el-pagination>
						</div>
					</div>
				</div>
				<div class="noTeacter" v-else-if="list.length === 0">
					<div class="noTeacter_img">
						<img src="../../assets/imgs/4.png" alt="">
						<div>抱歉，暂时没有您想要的内容</div>
					</div>
				</div>
			</el-tab-pane>

		</el-tabs>
	</div>
</template>

<script>
	export default {
		name: 'ArchiveCertificate',
		data() {
			return {
				activeName: 'first',
				list: [],
				yearsOptions: [], //年份数据
				yearsValue: '', //选中的年份
				page: 1,
				pageSize: 4,
				total: null
			};
		},
		created() {

			this.getByPage()
		},
		methods: {
			handleDownload(item) {
				let dload = document.createElement("a");
				dload.download = item.name; // 设置下载的文件名，默认是'下载'
				dload.href = item.url;
				dload.target = '_blank';
				document.body.appendChild(dload);
				dload.click();
				dload.remove(); // 下载之后把创建的元素删除
			},
			getArchive() {
				this.$http.post('myCertificateApi', {
					id: this.yearsValue
				}).then(res => {
					if (res.code === 200) {
						this.list = res.data.certificateNumberVoMyPage.records
					} else {
						this.$message.error(res.message)
					}
				})
			},
			handleCurrentChange(e) {
				this.page = e
				this.getByPage()
			},
			//分页查询
			getByPage() {
				let data = {
					pageNumber: this.page,
					pageSize: this.pageSize,

				}
				const loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				this.$http.post('myCertificateApi', data).then((res) => {
					if (res.code == 200) {
						this.list = res.data.certificateNumberVoMyPage.records
						this.total = res.data.certificateNumberVoMyPage.total
						this.yearsOptions = res.data.years
					} else {
						this.$message.error(res.message);
					}
					loading.close();
				})
			},


		}

	}
</script>

<style lang="less" scoped>
	.editInfoContent {
		width: 974px;
		height: 800px;
		background-color: #fff;
		overflow-y: auto;
		/deep/ .el-tabs__item {
			font-size: 24px;
			padding-bottom: 19px;
			padding-left: 21px !important;
			line-height: 63px;
			height: 63px;
		}

		/deep/ .el-tabs__nav-scroll {
			height: 63px;
		}

		/deep/ .el-tabs__active-bar {
			// left: 24px;
			background-color: #39AEFF;
		}

		/deep/ .el-tabs__item:hover {
			color: #39AEFF;
		}

		/deep/.el-tabs__item.is-active {
			color: #39AEFF;
		}

	}

	.info {
		padding: 25px;

		.notice {
			background: #EAEDFF;
			font-weight: 500;
			color: #333333;
			font-size: 16px;
			text-indent: 34px;
			padding: 17px 0;

		}

		.contentBox {
			// height: 520px;
			padding-bottom: 60px;
		}

		.userInfo {
			margin-top: 50px;
			display: flex;
			font-size: 16px;

			tr {
				height: 50px;
			}

			img {
				width: 100px;
				height: 100px;
			}
		}

		.btn {
			background: linear-gradient(163deg, #39AEFF 0%, #FF6759 100%);
			color: #fff;
			padding: 10px 20px;
			border-radius: 4px;
			font-size: 14px;
			line-height: 1;
		}
	}

	.noTeacter {
		// margin-left: 45%;
		color: #666;
		height: 60vh;
		text-align: center;
		margin-top: 200px;
		font-size: 14px;
		color: #ccc;

		.noTeacter_img {
			img {
				width: 188px;
				height: 141px;
				margin-bottom: 10px;
			}
		}
	}

	.tab_r {
		margin-top: 30px;
		display: flex;
		justify-content: flex-end;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
		background: #39AEFF;
	}
</style>
